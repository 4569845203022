$(document).ready(function() {

	// FORM VALIDATION

	// $( "#contactform" ).validate( {
	// 	rules: {
	// 		nome: {
	// 			required: true,
	// 			minlength: 2
	// 		},
	// 		cognome: {
	// 			required: true,
	// 			minlength: 2
	// 		},
	// 		email: {
	// 			required: true,
	// 			email: true
	// 		},
	// 		messaggio: {
	// 			required: true,
	// 			minlength: 6
	// 		},
	// 		privacy: "required"
	// 	},
	// 	messages: {
	// 		nome: {
	// 			required: "Per favore, inserisci il tuo nome",
	// 			minlength: "Il nome deve contenere almeno 2 caratteri"
	// 		},
	// 		cognome: {
	// 			required: "Per favore, inserisci il tuo cognome",
	// 			minlength: "Il cognome deve contenere almeno 2 caratteri"
	// 		},
	// 		email: {
	// 			required: "Per favore, inserisci un'indirizzo email valido",
	// 			email: true
	// 		},
	// 		messaggio: {
	// 			required: "Per favore, inserisci un messaggio",
	// 			minlength: "Il messaggio deve contenere almeno 6 caratteri"
	// 		},
	// 		privacy: "Per favore, accetta l'informativa"
	// 	},
	// 	errorElement: "em",
	// 	errorPlacement: function ( error, element ) {
	// 		error.addClass( "help-block" );
	// 		if ( element.prop( "type" ) === "checkbox" ) {
	// 			error.insertAfter( element.parent( "label" ) );
	// 		} else {
	// 			error.insertAfter( element );
	// 		}
	// 	},
	// 	highlight: function ( element ) {
	// 		$( element ).addClass( "is-invalid" ).removeClass( "is-valid" ).prev().addClass( "is-invalid" ).removeClass( "is-valid" );
	// 	},
	// 	unhighlight: function ( element, errorClass, validClass ) {
	// 		$( element ).addClass( "is-valid" ).removeClass( "is-invalid" ).prev().addClass( "is-valid" ).removeClass( "is-invalid" );
	// 	}
	// } );



	// STICKY HEADER

	$(document).on('scroll', function() {

		var header = $('header#header').outerHeight(true);

		if ( $(document).scrollTop() > header ) {
			$('#header').addClass('shrink');
		} else {
			$('#header').removeClass('shrink');
		}

	});


	// SMOOTH SCROLL
	$('a[href^="#"]').not('[href="#"]').not('a.nav-link, .carousel-control-prev, .carousel-control-next').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});


	// MOSTRA CAMPO TIPOLOGIA_LEAD SOLO SE ESISTE PIU' DI 1 TIPOLOGIA

	var first = $('#tipologia_lead').find('option').eq(0).val();

	if (first != '') {
		var n = $('#tipologia_lead option').length;
		if (n>1) {
			$('#tipologia_lead_container').removeClass('d-none');
			$('<option value="">Seleziona...</option>').insertBefore($('#tipologia_lead option:first-child'));
			$('#tipologia_lead option:first-child').attr('selected',true);
			$('#tipologia_lead').addClass('required');
			$('<div class="field_status">').insertAfter('#tipologia_lead');
			$("#tipologia_lead").val($("#tipologia_lead option:first").val());
		} else {
			$('#tipologia_lead_container').addClass('d-none');
		}
	};



	// FORM REPLACE

	$('input[type="submit"]').prop("value", "Invia la richiesta");
	$(".required").parent('div').find('label').append('<span class="ast">*</span>');



	// BACK TO TOP

	function detectPosition(top){
		if($(window).scrollTop() > top){
			return 1
		}else{
			return 0;
		}
	}
	
	function fadeTop(x){
		if(x==1){
			$('#top').fadeIn(300)
		}else{
			$('#top').fadeOut(300)
		}
	}

	function actionTop(top){
		x = detectPosition(top);
		fadeTop(x)
	}

	if($('#top').length > 0){

		var top = 220;
		var x = 0;
		actionTop(top)

		$(window).scroll(function(){
			actionTop(top)
		})

		$('#top').click(function(e){
			e.preventDefault();
			$('html,body').animate({'scrollTop':0},750);
		});
	};

});